<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="850"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Message</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0 h-75px">
            <v-textarea
              label="Message"
              v-model="formData.message"
              clearable
              height="400"
              outlined
            ></v-textarea>
          </form>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="toggleModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "EditMessage",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      message_id: null,
      message: null,
    },
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    submitEditForm() {
      let data = this.convertToFormData();
      if (!data) {
        this.message("Error", "You should input all data", "error");
        return;
      }
      this.pageLoader(true);
      ApiService.post("/datahub/messages/update", data)
        .then(() => {
          this.message(
            "Updated",
            "The Message data has been updated!",
            "success"
          );
          this.toggleModal();
          this.pageLoader(false);
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      data.append("message_id", this.formData.message_id);
      let obj = JSON.parse(this.formData.message);
      data.append("message", JSON.stringify(obj));
      return data;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    prettyPrint() {
      let obj = JSON.parse(this.$store.getters.getItemForAction.message.data);
      return JSON.stringify(obj, undefined, 4);
    },
    resetData() {
      this.formData.message_id = this.$store.getters.getItemForAction.id;
      this.formData.message = this.prettyPrint();
    },
  },
};
</script>
